<template>
  <div>
    <b-button
      v-b-modal.my-modal
      style="float: right"
      variant="primary"
      v-on:click="showCreate()"
      >Thêm mới
    </b-button>
    <b-modal id="my-modal" hide-footer ref="myModal">
      <b-form class="col-12">
        <b-form-group label="Tiêu đề (*):">
          <b-form-input
            v-model="config.title"
            placeholder="Nhập tiêu đề cảnh báo"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Loại cảnh báo (*):">
          <b-form-select
            v-model="config.type"
            :options="typeOptions"
          ></b-form-select>
        </b-form-group>


        <div v-if="config.type == 'VOUCHER'">
          <b-form-group label="Nhập ID voucher theo dõi (các ID cách nhau bởi dấu phẩy) (*)">
            <b-form-input
                v-model="config.code"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Cảnh báo số lượng khi còn dưới (%) (*)">
            <b-form-input
                v-model="config.percent"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group v-if="isShowBalance" label="Nhà cung cấp (*): ">
            <b-form-select
                v-model="config.provider"
                :options="providerOptions"
            ></b-form-select>
          </b-form-group>
          <b-form-group v-if="isShowstatusType" label="Loại trạng thái GD (*):">
            <multiselect
                v-model="config.statusType"
                :options="statusOption"
                :multiple="true"
                label="text"
                track-by="value"
            ></multiselect>
          </b-form-group>
          <b-form-group label="Loại kịch bản (*):">
            <b-form-select
                v-model="config.scriptType.index"
                :options="scriptType.options"
            ></b-form-select>
          </b-form-group>
        <b-form-group label="Loại kịch bản (*):">
          <b-row v-if="scriptType.isShow.action">
            <b-col sm="8">action</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.action"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.action_type">
            <b-col sm="8">action_type</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.action_type"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.product_id">
            <b-col sm="8">product_id</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.product_id"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.merchant_code">
            <b-col sm="8">merchant_code</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.merchant_code"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.receiver_type">
            <b-col sm="8">receiver_type</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.receiver_type"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.type_link">
            <b-col sm="8">type_link</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.type_link"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.bank_code">
            <b-col sm="8">bank_code</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.bank_code"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
          <b-row v-if="scriptType.isShow.type">
            <b-col sm="8">type</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.scriptType.type"
              ></b-form-input>
            </b-col>
            <br />
          </b-row>
        </b-form-group>
        <!--<b-form-group v-if="isShowTransaction" label="Mã điều kiện (*):">-->
        <!--<b-form-textarea-->
        <!--id="textarea"-->
        <!--v-model="config.code"-->
        <!--placeholder="Mã điều kiện (code) ..."-->
        <!--rows="2"-->
        <!--max-rows="4"-->
        <!--&gt;</b-form-textarea>-->
        <!--</b-form-group>-->
        <b-form-group label="Điều kiện (*):">
          <b-row v-if="isShowAmount">
            <b-col sm="8">Số tiền (VNĐ)</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.condition.amount.value"
                required
                placeholder="Điều kiện áp dụng (*):"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="isShowTransaction">
            <b-col sm="8">Giao dịch không thành công (lần)</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.condition.number_transaction.value"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="isShowTransaction">
            <b-col sm="8">Tỷ lệ thành công (%)</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.condition.ratio_transaction.value"
              ></b-form-input>
            </b-col>
            <b-col sm="8">Trong thời gian (phút)</b-col>
            <b-col sm="4">
              <b-form-input
                id="input-1"
                v-model="config.condition.ratio_transaction.time"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        </div>

        <b-form-group label="Tin nhắn cảnh báo (*):">
          <b-form-textarea
              id="textarea"
              v-model="config.message_response"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Kích hoạt (*):">
          <b-form-select
            v-model="config.active"
            :options="activeOption"
          ></b-form-select>
        </b-form-group>
        <b-row style="float: right">
          <b-button
            type="submit"
            variant="primary"
            v-on:click="store()"
            v-if="isCreate"
            >Tạo mới
          </b-button>
          <b-button type="submit" variant="primary" v-on:click="update()" v-else
            >Cập nhật
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
    <br />
    <br />
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách config</h6>
        </template>
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(status_type)="data">
            <p v-for="(item, i) in data.item.status_type" v-bind:key="i">
              {{ item }}
            </p>
          </template>
          <template #cell(conditions)="data">
            <p v-for="(item, i) in data.item.conditions" v-bind:key="i">
              {{ i }}: {{ item }}
            </p>
          </template>
          <template #cell(active)="data">
            <b-badge v-if="data.item.active" variant="success"
              >Hoạt động</b-badge
            >
            <b-badge v-else variant="warning">Ngừng hoạt động</b-badge>
          </template>
          <template #cell(options)="data">
            <button @click="showUpdate(data.item)">
              <i class="fa fas fa-edit bluex" aria-hidden="true"></i>
            </button>
            &nbsp;
            <button variant="outline-danger" @click="deleteItem(data.item.id)">
              <i class="fa fa-trash red" aria-hidden="true"></i>
            </button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.total"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);
export default {
  components: {
    Multiselect,
  },
  mixins: [Common],
  data() {
    return {
      quantities: [],
      config: {
        id: null,
        title: null,
        code: null,
        active: 0,
        percent: null,
        provider: null,
        condition: {
          amount: {
            value: 0,
          },
          number_transaction: {
            value: 0,
          },
          ratio_transaction: {
            value: 0,
            time: 0,
          },
        },
        type: [],
        statusType: [],
        message_response: null,
        scriptType: {
          index: null,
          action: null,
          action_type: null,
          product_id: null,
          merchant_code: null,
          receiver_type: null,
          type_link: null,
          bank_code: null,
          type: null,
          value: null,
        },
      },
      providerOptions: [
        { value: "EPAY", text: "Epay" },
        { value: "IMEDIA", text: "Imedia" },
      ],
      statusOption: [
        { value: "PENDING", text: "Pending" },
        { value: "FALSE", text: "Thất bại" },
        { value: "CANCEL", text: "Hủy" },
      ],
      activeOption: [
        { value: 0, text: "không hoạt động" },
        { value: 1, text: "Hoạt động" },
      ],
      typeOptions: [
        { value: null, text: "Please select an option" },
        { value: "BALANCE", text: "Cảnh báo số dư" },
        { value: "FUND", text: "Cảnh báo nguồn tiền" },
        { value: "SERVICE", text: "Cảnh báo dịch vụ" },
        { value: "GMV", text: "Cảnh báo GMV" },
        { value: "VOUCHER", text: "Cảnh báo Voucher" },
      ],
      show: true,
      fields: [
        { id: "Id" },
        { title: "Tiêu đề" },
        { type: "Loại cảnh báo" },
        { active: "Trạng thái" },
        { message_response: "Tin nhắn cảnh báo" },
        { options: "Tùy chọn" },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        lastPage: 1,
        total: 1,
        skip: 0,
      },
      isCreate: true,
      isShowTransaction: true,
      isShowstatusType: true,
      isShowBalance: true,
      isShowAmount: true,
      scriptType: {
        value: null,
        options: [
          { value: null, text: "Loại kịch bản" },
          { value: 1, text: "Hành động giao dịch trên transactions" },
          { value: 2, text: "Loại hành động giao dịch trên transactions" },
          { value: 3, text: "Sản phẩm thanh toán trên orders" },
          { value: 4, text: "Hành động đơn hàng trên orders" },
          { value: 5, text: "Merchant của đơn hàng trên orders" },
          { value: 6, text: "Receiver Type trên transactions" },
          { value: 7, text: "Loại thanh toán trên transactions" },
          { value: 8, text: "Loại đơn hàng trên orders:" },
        ],
        isShow: {
          action: false,
          action_type: false,
          product_id: false,
          merchant_code: false,
          receiver_type: false,
          type_link: false,
          bank_code: false,
          type: false,
          value: false,
        },
      },
    };
  },
  methods: {
    formatInput() {
      this.config = {
        id: null,
        title: null,
        code: null,
        active: 0,
        provider: null,
        condition: {
          amount: {
            value: 0,
          },
          number_transaction: {
            value: 0,
          },
          ratio_transaction: {
            value: 0,
            time: 0,
          },
        },
        message_response: null,
        type: [],
        statusType: [],
        scriptType: {
          index: null,
          action: null,
          action_type: null,
          product_id: null,
          merchant_code: null,
          receiver_type: null,
          type_link: null,
          bank_code: null,
          type: null,
          value: null,
        },
      };
    },

    formatScriptType(index, show) {
      if (show) {
        this.config.scriptType = {
          index: index,
          action: null,
          action_type: null,
          product_id: null,
          merchant_code: null,
          receiver_type: null,
          type_link: null,
          bank_code: null,
          type: null,
          value: null,
        };
      }
    },
    listAll(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.$route.query.page = numberPage;
      let params = {
        page: numberPage,
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.listAll(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.last_page;
            this.notifyAlert("success", "Lấy danh sách thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    store() {
      let check = this.validateInput();
      if (!check) return;
      let arrayStatusType = [];
      this.config.statusType.forEach((item) => {
        arrayStatusType.push(item.value);
      });
      if (arrayStatusType.length == 0) {
        arrayStatusType = null;
      }

      let params = {
        title: this.config.title,
        type: this.config.type,
        provider: this.config.provider,
        condition: this.config.condition,
        statusType: arrayStatusType,
        // code: this.config.code,
        message_response: this.config.message_response,
        active: this.config.active,
        script_type: this.config.scriptType,
      };

      if (this.config.type == 'VOUCHER') {
        params.condition = this.config.percent
        params.code = this.config.code

      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.storeConfig(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert("success", response.data.message);
          }
          this.listAll();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$refs.myModal.hide();
      this.listAll();
    },
    update() {
      let check = this.validateInput();
      if (!check) return;
      this.$bus.$emit("show-loading", true);
      let arrayStatusType = [];
      this.config.statusType.forEach((item) => {
        arrayStatusType.push(item.value);
      });
      if (arrayStatusType.length == 0) {
        arrayStatusType = null;
      }
      let params = {
        id: this.config.id,
        title: this.config.title,
        type: this.config.type,
        provider: this.config.provider,
        condition: this.config.condition,
        statusType: arrayStatusType,
        // code: this.config.code,
        message_response: this.config.message_response,
        active: this.config.active,
        script_type: this.config.scriptType,
      };

      if (this.config.type == 'VOUCHER') {
        params.condition = this.config.percent
        params.code = this.config.code

      }
      CmsRepository.updateConfig(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          }
          this.listAll();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$refs.myModal.hide();
    },
    showUpdate(data) {
      this.show = false;
      this.isCreate = false;
      this.config.id = data.id;
      this.config.message_response = data.message_response;
      this.config.title = data.title;
      this.config.type = data.type;
      this.config.provider = data.provider;
      this.config.status_type = data.status_type;
      this.config.code = data.code;
      this.config.condition = data.conditions;
      this.config.percent = data.conditions;
      this.config.active = data.active;
      this.config.scriptType = data.script_type;
      this.showType();
      this.$refs["myModal"].show();
    },
    deleteItem(id) {
      let params = {
        id: id,
      };
      if (!confirm("Bạn chắc chắn muốn xóa chứ?")) {
        return;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.deleteConfig(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert("success", response.data.message);
          }
          this.listAll();
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    validateInput() {
      if (this.config.title == null) {
        this.notifyAlert("warn", "Tiêu đề không được bỏ trống");
        return false;
      }
      if (this.config.type == null) {
        this.notifyAlert("warn", "Loại cảnh báo không được bỏ trống");
        return false;
      }
      if (this.config.scriptType.index == null && this.config.type != 'VOUCHER') {
        this.notifyAlert("warn", "Loại kịch bản không được bỏ trống");
        return false;
      }

      if (
          (this.config.message_response == null ||
        this.config.message_response == "")
      ) {
        this.notifyAlert("warn", "Tin nhắn cảnh báo không được bỏ trống");
        return false;
      }

      switch (this.config.type) {
        case "BALANCE":
          if (this.config.provider == null) {
            this.notifyAlert("warn", "Nhà cung cấp không được bỏ trống");
            return false;
          } else if (
            this.config.condition.amount.value == null ||
            this.config.condition.amount.value == ""
          ) {
            this.notifyAlert("warn", "Số tiền không được bỏ trống");
            return false;
          }
          break;
        case "FUND":
        case "SERVICE":
          if (this.config.statusType.length == 0) {
            this.notifyAlert(
              "warn",
              "Loại trạng thái giao dịch không được bỏ trống"
            );
            return false;
          }
          break;
        case "GMV":
          break;
        case "VOUCHER":
          if (this.config.code == null || this.config.code == '') {
            this.notifyAlert(
                "warn",
                "ID voucher không được bỏ trống"
            );
            return false;
          }
          if (this.config.percent == null || this.config.percent == 0) {
            this.notifyAlert(
                "warn",
                "% phải là số lớn hơn 0"
            );
            return false;
          }
          break;
        default:
          break;
      }

      // switch (this.config.scriptType.index) {
      //   case 1:
      //   case 4:
      //     if (
      //       this.config.scriptType.action == null ||
      //       this.config.scriptType.action == ""
      //     ) {
      //       this.notifyAlert("warn", "action không được bỏ trống");
      //       return false;
      //     }
      //     break;
      //   case 2:
      //     if (
      //       this.config.scriptType.action_type == null ||
      //       this.config.scriptType.action_type == ""
      //     ) {
      //       this.notifyAlert("warn", "action_type không được bỏ trống");
      //       return false;
      //     }
      //     break;
      //   case 3:
      //     if (
      //       this.config.scriptType.product_id == null ||
      //       this.config.scriptType.product_id == ""
      //     ) {
      //       this.notifyAlert("warn", "product_id không được bỏ trống");
      //       return false;
      //     }
      //     break;
      //   case 5:
      //     if (
      //       this.config.scriptType.merchant_code == null ||
      //       this.config.scriptType.merchant_code == ""
      //     ) {
      //       this.notifyAlert("warn", "merchant_code không được bỏ trống");
      //       return false;
      //     }
      //     break;
      //   case 6:
      //     if (
      //       this.config.scriptType.receiver_type == null ||
      //       this.config.scriptType.receiver_type == ""
      //     ) {
      //       this.notifyAlert("warn", "receiver_type không được bỏ trống");
      //       return false;
      //     }
      //     break;
      //   case 7:
      //     if (
      //       this.config.scriptType.type_link == null ||
      //       this.config.scriptType.type_link == "" ||
      //       this.config.scriptType.bank_code == null ||
      //       this.config.scriptType.bank_code == ""
      //     ) {
      //       this.notifyAlert(
      //         "warn",
      //         "type_link và bank_code không được bỏ trống"
      //       );
      //       return false;
      //     }
      //     break;
      //   case 8:
      //     if (
      //       this.config.scriptType.type == null ||
      //       this.config.scriptType.type == ""
      //     ) {
      //       this.notifyAlert("warn", "type không được bỏ trống");
      //       return false;
      //     }
      //     break;
      // }
      return true;
    },
    showCreate() {
      this.show = true;
      this.formatInput();
      this.isCreate = true;
    },
    showType() {
      if (this.config.type === "BALANCE") {
        this.isShowTransaction = false;
        this.isShowstatusType = false;
        this.isShowBalance = true;
        this.isShowAmount = true;
      } else {
        this.isShowTransaction = true;
        this.isShowstatusType = true;
        this.isShowBalance = false;
        this.isShowAmount = false;
      }
      if (this.config.type === "GMV") {
        this.isShowAmount = true;
        this.isShowstatusType = false;
      }
    },
    showScriptType(type) {
      this.hideConfig();
      switch (type) {
        case 1:
          this.scriptType.isShow.action = true;
          break;
        case 2:
          this.scriptType.isShow.action_type = true;
          break;
        case 3:
          this.scriptType.isShow.product_id = true;
          break;
        case 4:
          this.scriptType.isShow.action = true;
          break;
        case 5:
          this.scriptType.isShow.merchant_code = true;
          break;
        case 6:
          this.scriptType.isShow.receiver_type = true;
          break;
        case 7:
          this.scriptType.isShow.type_link = true;
          this.scriptType.isShow.bank_code = true;
          break;
        case 8:
          this.scriptType.isShow.type = true;
          break;
        default:
          break;
      }
    },
    hideConfig() {
      this.scriptType.isShow.action = false;
      this.scriptType.isShow.action_type = false;
      this.scriptType.isShow.product_id = false;
      this.scriptType.isShow.merchant_code = false;
      this.scriptType.isShow.receiver_type = false;
      this.scriptType.isShow.type_link = false;
      this.scriptType.isShow.bank_code = false;
      this.scriptType.isShow.type = false;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Công cụ", route: "tools" },
      { title: "Config Notifications", route: "config-notifications" },
    ]);
    this.listAll();
  },
  watch: {
    "config.type"() {
      this.showType();
    },
    "config.scriptType.index"() {
      this.formatScriptType(this.config.scriptType.index, this.show);
      this.showScriptType(this.config.scriptType.index);
    },
    currentPage() {
      this.listAll();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
legend {
  font-weight: bold;
}
.bluex {
  color: #6262ff;
}
.red {
  color: red;
}
</style>
